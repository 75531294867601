<template>
	<div class="container">
		<div class="row">
			<div class="col-lg-12 text-center">
				<h2 class="mt-5">TR Dashboard</h2>
				<md-field>
					<label>Enter Password...</label>
					<md-input v-model="password" type="password" required></md-input>
				</md-field>
				<div class="alert alert-danger" v-if="error">Incorrect Password</div>
				<div>
					<md-button
						tag="md-success"
						class="md-raised"
						type="submit"
						@click="login()"
						>Submit</md-button
					>
				</div>
			</div>
		</div>
		<div style="text-align:center;position:absolute;bottom:2%;opacity:0.4">
			VERSION 3.4
		</div>
	</div>
</template>

<script>
	import hostsService from "../services/HostsService";

	export default {
		name: "Index",
		data() {
			return {
				password: "",
				error: false,
			};
		},
		methods: {
			async login() {
				//const response = await hostsService.tryLogin({ password: this.password });
				//console.log(response.data);
				try {
					const res = await this.$store.dispatch("getAuthToken", {
						password: this.password,
					});
					console.log("RES", res);
					this.$router.push("/dashboard");
				} catch (err) {
					console.log("Error", err);
					alert("Invalid Password");
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	h3 {
		text-align: center;
	}
</style>
