<template>
  <div id="app">
    <md-toolbar class="md-secondary d-flex justify-content-start">
      <router-link class="md-raised" tag="md-button" :to="'/'"
        >Home</router-link
      >
      <router-link
        v-if="false"
        class="md-raised"
        tag="md-button"
        :to="'/dashboard'"
        >Dashboard</router-link
      >
      <md-button class="button ml-2 md-raised" tag="md-button" @click="logout()"
        >LogOut</md-button
      >
      <!-- <a class="md-raised" target="_BLANK" tag="router-link" href="https://landing.triviarat.com/">Landing Page</a> -->
    </md-toolbar>

    <router-view />
  </div>
</template>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}
</style>

<script>
import hostsService from "./services/HostsService";
import LoginPage from "./components/Index";

export default {
  data() {
    return {
      authed: false,
    };
  },
  components: { LoginPage },
  methods: {
    async isAuth() {
      const response = await hostsService.isAuth();
      this.authed = response.data;
    },
    async logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
  mounted() {
    // this.isAuth();
  },
};
</script>
