<template>
	<div class="hosts">
		<div v-if="hosts.length > 0 && !loading" class="py-4 mx-5">
			<div class="d-flex justify-content-start w-50">
				<md-field>
					<md-label>Limit</md-label>
					<md-input v-model="limit" placeholder="Limit..." />
				</md-field>
				<!-- <md-field class="ml-4">
					<md-label>Cost</md-label>
					<md-input v-model="cost" placeholder="Cost" />
				</md-field> -->
			</div>
			<div class="d-flex justify-content-end">
				<button class="btn btn-sm" @click="blockEmails = !blockEmails">
					Show Emails
				</button>
				<button class="btn btn-sm" @click="gethosts()">Refresh</button>
				<button class="btn btn-sm btn-info" @click="changeLimit()">
					{{ !limit ? "Showing All" : "Limiting to 40" }}
				</button>
				<button class="mx-2 btn btn-sm btn-info" @click="active = !active">
					{{ !active ? "All Status'" : "Only Active" }}
				</button>
			</div>
			<div v-if="currentHost">
				<Host :hostId="currentHost" @back="currentHost = null" />
			</div>
			<div>
				COUNT: {{ rowCount }} |
				<vue-good-table
					width="80"
					:columns="columns"
					:rows="hosts"
					theme="black-rhino"
					@on-column-filter="getCount()"
					@on-search="getCount()"
					:search-options="{
						enabled: true,
						trigger: 'enter',
						placeholder: 'Search...',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field == 'email'">
							<!-- <span
							><router-link :to="'/host/' + props.row._id">{{
								props.row.email
							}}</router-link></span
						> -->
							<md-button
								class="md-secondary"
								@click="currentHost = props.row._id"
							>
								{{ props.row.email }}
							</md-button>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template> </vue-good-table
				><
			</div>
		</div>
		<div class="d-flex justify-content-center py-5" v-if="loading">
			<md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
		</div>
	</div>
</template>

<script>
	import hostsService from "../services/HostsService";
	import Host from "./Host.vue";
	export default {
		name: "hosts",
		components: {
			Host,
		},
		data() {
			return {
				authed: false,
				loading: false,
				hosts: [],
				limit: 60,
				active: true,
				currentHost: null,
				blockEmails: true,
				cost: 19,
				rowCount: 0,
				columns: [
					{
						label: "Email",
						field: "email",
						//formatFn: this.messEmail,
					},
					{
						label: "Code",
						field: "room.roomCode",
					},
					{
						label: "Players",
						field: "room.playerCount",
						sortable: true,
						type: Number,
						filterOptions: {
							enabled: true,
							placeholder: "Filter values",
							filterFn(value, searchTerm, row) {
								if (!searchTerm) {
									return true;
								}

								const num = parseFloat(value);
								const threshold = parseFloat(searchTerm);

								return !isNaN(num) && !isNaN(threshold) && num > threshold;
							},
						},
					},
					{
						label: "UTM",
						field: "source",
						filterOptions: {
							enabled: true,
							placeholder: "All Records",
							trigger: "enter",
							filterFn: this.mustExist,
							filterDropdownItems: ["Has Source"],
						},
					},
					{
						label: "Created At",
						field: "createdAt",
						formatFn: this.formatDate,
					},
					{
						label: "Last Login",
						field: "lastLogin",
						formatFn: this.formatDate,
					},
					{
						label: "Status",
						field: "subscriptionStatus",
						filterOptions: {
							enabled: true,
							placeholder: "All..",
							trigger: "enter",
							filterDropdownItems: ["CANCELED", "ACTIVE"],
						},
					},
					{
						label: "SubEnd",
						field: "subscriptionEnd",
						formatFn: this.formatDate,
						filterOptions: {
							enabled: true,
							placeholder: "All..",
							trigger: "enter",
							filterFn: this.mustExist,
							filterDropdownItems: ["Has Date"],
						},
					},
				],
			};
		},
		created() {
			this.gethosts();
		},
		computed: {
			earnings() {
				const cost = this.cost;
				const e = this.hosts.reduce((acc, cur) => {
					if (cur.subscriptionId) {
						acc += cost;
					}
					return acc;
				}, 0);
				return e;
			},
		},
		methods: {
			async gethosts() {
				this.loading = true;
				const response = await hostsService.fetchHosts(this.limit, this.active);
				this.hosts = response.data.hosts.map((v) => {
					if (v.source === "web") {
						v.source = null;
					}
					return v;
				});
				this.loading = false;
				this.getCount(this.hosts.length);
			},
			//return date like this: "Wed, 26 Jul 2023" without the time
			formatDate(value) {
				var date = new Date(value);
				return date
					.toUTCString()
					.split(" ")
					.slice(0, 4)
					.join(" ");
			},
			changeLimit() {
				this.limit = !this.limit ? 40 : null;
				this.gethosts();
			},
			formatDate_(value) {
				var date = new Date(value);
				return date.toUTCString();
			},
			messEmail(email) {
				return `LINK: <router-link :to="/email">router</router-link>`;

				return this.blockEmails
					? email.substr(0, 2) + "*****" + email.substr(10, email.length)
					: email;
			},
			mustExist(data, string) {
				return data;
			},
			async checkAuth() {
				const response = await hostsService.isAuth();
				if (response.data) {
					this.gethosts();
				}
			},
			async getCount(count = null) {
				if (count) {
					this.rowCount = count;
					return;
				}
				console.log("refreshing");
				let table = await document.getElementById("vgt-table");
				this.rowCount = table.rows.length - 2;
			},
		},
	};
</script>

<style type="text/css">
	.table-wrap {
		width: 60%;
		margin: 0 auto;
		text-align: center;
	}
	table th,
	table tr {
		text-align: left;
	}
	table thead {
		background: #f2f2f2;
	}
	table tr td {
		padding: 10px;
	}
	table tr:nth-child(odd) {
		background: #f2f2f2;
	}
	table tr:nth-child(1) {
		background: #4d7ef7;
		color: #fff;
	}
	a {
		color: #4d7ef7;
		text-decoration: none;
	}
	a.add_host_link {
		background: #4d7ef7;
		color: #fff;
		padding: 10px 80px;
		text-transform: uppercase;
		font-size: 12px;
		font-weight: bold;
	}
</style>
