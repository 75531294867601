<template>
	<div class="container">
		<div v-if="loading">Loading...</div>
		<div v-if="host" class="py-4 mx-5">
			<div class="d-flex justify-content-end">
				<button class="btn btn-sm" @click="getHost()">Refresh</button>
			</div>

			<div class="host-list text-left">
				<button class="btn btn mb-4" @click="$emit('back')">< BACK</button>

				<h3>HOST</h3>
				<strong>
					<a :href="`mailto:${host.email}`">{{ host.email }}</a>
				</strong>
				<div v-for="(key, val) in Object.keys(host)">
					<div class="subtitle">{{ key }}</div>

					<div class="p-text" v-if="typeof host[key] === 'object'">
						<div v-for="(k, v) in Object.keys(host[key])">
							<div
								v-if="typeof host[key][k] === 'object' && host[key][k] !== null"
							>
								<div class="subtitle">{{ k }}</div>
								<div v-for="(k2, v2) in Object.keys(host[key][k])">
									<div class="p-text">{{ k2 }}: {{ host[key][k][k2] }}</div>
								</div>
							</div>
							<div v-else>{{ k }}: {{ host[key][k] }}</div>
						</div>
					</div>

					<div class="p-text" v-else>{{ host[key] }}</div>
				</div>
			</div>
		</div>
		<div class="d-flex justify-content-center py-5" v-else>
			<md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
		</div>
	</div>
</template>

<script>
	import hostsService from "../services/HostsService";

	export default {
		name: "host",
		data() {
			return {
				authed: false,
				host: {},
				loading: false,
			};
		},
		mounted() {
			this.getHost();
		},
		props: {
			hostId: {
				type: String,
				required: true,
			},
		},
		methods: {
			async getHost() {
				this.loading = true;
				const id = this.hostId; //$route.params.id;
				const response = await hostsService.showHost(id);
				this.host = response.data;
				this.loading = false;
			},
			//return date like this: "Wed, 26 Jul 2023" without the time
		},
	};
</script>

<style type="text/css">
	.subtitle {
		font-size: 0.8rem;
		font-weight: lighter;
		color: gray;
		padding-top: 12px;
	}

	.p-text {
		font-weight: bold;
		margin-left: 12px;
	}
</style>
