import Vue from "vue";
import Router from "vue-router";
import Index from "./components/Index.vue";
import Hosts from "./components/Hosts.vue";
import Host from "./components/Host.vue";
import store from "./store";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{ path: "/", component: Index },
		{ path: "/login", component: Index },
		//{ path: '/dashboard', component: Hosts }
		{
			path: "/dashboard",
			component: Hosts,
			beforeEnter: (to, from, next) => {
				// check if the user is authenticated before entering the route
				if (store.getters.isAuthenticated) {
					next(); // user is authenticated, proceed to the route
				} else {
					next("/login"); // user is not authenticated, redirect to login
				}
			},
		},
		{
			path: "/host/:id",
			component: Host,
			beforeEnter: (to, from, next) => {
				if (store.getters.isAuthenticated) {
					next(); // user is authenticated, proceed to the route
				} else {
					next("/login"); // user is not authenticated, redirect to login
				}
			},
		},
	],
});
