import Vue from "vue";
import Vuex from "vuex";
import axios from "axios"; // assuming you are using axios for HTTP requests
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authToken: Cookies.get("authToken") || null,
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
  },
  mutations: {
    SET_AUTH_TOKEN(state, token) {
      state.authToken = token;
      Cookies.set("authToken", token);
    },
    CLEAR_AUTH_TOKEN(state) {
      state.authToken = null;
      Cookies.remove("authToken"); // remove authToken from cookie
    },
  },
  actions: {
    async getAuthToken({ commit }, payload) {
      const baseURL = process.env.VUE_APP_BASE_URL + "/login";
      try {
        const response = await axios.post(baseURL, payload);
        if (response.data && response.data.token) {
          commit("SET_AUTH_TOKEN", response.data.token);
          return Promise.resolve();
        }
      } catch (error) {
        console.error("Failed to get auth token:", error);
        return Promise.reject();
      }
    },
    logout({ commit }) {
      commit("CLEAR_AUTH_TOKEN");
    },
  },
});
