import Api from "@/services/Api";

export default {
	fetchHosts(limit = null, active = false) {
		if (limit) {
			return Api().get(`hosts?limit=${limit}&active=${active}`);
		}
		return Api().get("hosts");
	},

	showHost(id) {
		return Api().get(`hosts/${id}`);
	},

	tryLogin(params) {
		return Api().post("login/", params);
		// return params == 'rat123' ? true : false
	},

	isAuth() {
		return Api().get("/auth");
	},

	updatehost(params) {
		return Api().put("hosts/" + params.id, params);
	},

	gethost(params) {
		return Api().get("host/" + params.id);
	},

	deletehost(id) {
		return Api().delete("hosts/" + id);
	},
};
