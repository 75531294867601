import axios from "axios";
require("dotenv").config();
const baseURL = process.env.VUE_APP_BASE_URL;
console.log("BaseURL", baseURL);
export default () => {
	return axios.create({
		baseURL: baseURL,
		headers: { "Access-Control-Allow-Origin": "*", "x-Trigger": "CORS" },
	});
};
